// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$velzon-primary: mat.define-palette(mat.$blue-palette);
$velzon-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$velzon-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$velzon-theme: mat.define-light-theme(
  (
    color: (
      primary: $velzon-primary,
      accent: $velzon-accent,
      warn: $velzon-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($velzon-theme);

/* You can add global styles to this file, and also import other style files */
//import ag-grid trong trong app.scss
//@import 'ag-grid-community/dist/styles/ag-grid.css';
//@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// regular style toast
@import "ngx-toastr/toastr";

@import "swiper/swiper";
@import "swiper/modules/free-mode/free-mode";
@import "swiper/modules/navigation/navigation";
@import "swiper/modules/thumbs/thumbs";
@import "swiper/modules/pagination/pagination";
@import "swiper/modules/zoom/zoom";

//@import "~swiper/scss";
//@import "~swiper/scss/free-mode";
//@import "~swiper/scss/navigation";
//@import "~swiper/scss/thumbs";
//@import "~swiper/scss/pagination";
//@import "~swiper/scss/zoom";

html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.hl-mb-n2 {
  margin-bottom: -2px;
}

/* Xoá hiệu ứng css cho agm-map-content
/* Remove the X, close button */
.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw {
  padding: 0 !important;
}

/* remove overflow scroll */
.gm-style-iw-d {
  overflow: hidden !important;
}

.mat-mdc-dialog-container {
  position: relative !important;
}

.form-group[required] label:after {
  content: " *";
  color: red;
}

textarea {
  resize: none !important;
}

.error {
  animation: shake 0.6s 1;
}

.form-control[readonly] {
  opacity: 0.6;
  background-color: var(--vz-input-disabled-bg);
}

.disabled {
  opacity: 0.6 !important;
  pointer-events: none !important;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
}

.bg-success-custom {
  background-color: #daf4f0;
}

.bg-danger-custom {
  background-color: #fde8e4;
}

.animation-rotate {
  transition: 0.2s ease-in-out;
  rotate: 90deg;
}

.animation-rotate-back {
  transition: 0.2s ease-in-out;
  rotate: 0deg;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes shake {
  0% {
    transform: translateX(-18px);
  }

  20% {
    transform: translateX(18px);
  }

  40% {
    transform: translateX(-18px);
  }

  60% {
    transform: translateX(18px);
  }

  80% {
    transform: translateX(-8px);
  }

  100% {
    transform: translateX(0px);
  }
}

// customize css
ng-select.ng-invalid.ng-touched {
  .ng-select-container {
    border-color: red !important;
  }
}


.multiline-tooltip{
  white-space: pre;
}