.custom-confirm-button {
  &:focus {
    box-shadow: none;
  }
}

.no-border {
  border: none !important;
}

.custom-swal2-confirm {
  background-color: #0ab39c !important;
  border: #0ab39c;
}