/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Version: 1.6.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Custom Bootstrap Css File
*/

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/bootstrap';
