//
// _leaflet-maps.scss
//

.leaflet-map {
  height: 300px;
  &.leaflet-container {
    z-index: 0;
    font-family: $font-family-primary;
  }
}
